import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";


export default function CityImagining() {
  return (
    <Layout pageTitle="A City Imagining">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Lighting - USI Projects</title>
        <meta name="description" content="The Belfast Entries Lighting was commissioned by Belfast city Council to look at reimagining how we can transform our historic entries."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
         <section class="text-gray-700 body-font heading-container-lighting">

         <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Lighting</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                  Belfast City Council</p>
              </div>
          </section>
        </div>
      </section>





      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-blue-900">OVERVIEW
                </h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl ">
                  The Belfast Entries Lighting was commissioned by Belfast city Council to look at reimagining 
                  how we can transform our historic entries through distinctive lighting interventions. 
                  The lights were designed through the double diamond process. Starting with the initial 
                  co-design workshops in our City centre culture shop to defining city lighting principles 
                  and then delivering a series of dynamic pieces changing how we view and use these spaces.
                </p>
              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase">Fields of
                  Practice</p>
                <p
                  class="my-4 text-gray-500 leading-relaxed mt-0">Communities<br />Resilience<br />Public
                  Space<br />Culture<br />Regeneration<br />Tourism<br />Economy<br />Technology</p>
                  <p class="text-2xl font-bold uppercase mt-8">Services</p>
                  <p class="my-4 text-gray-500 leading-relaxed mt-0">Engagement<br/>
                    Urban Strategies<br/>Lighting<br/>Installations<br/>3D Printing</p>
               
              </div>
            </div>
          </section>
        </div>
      </section>

      <div className="mx-auto md:p-16 md:m-16">
        <video className='mx-auto' controls src="https://player.vimeo.com/progressive_redirect/playback/670729967/rendition/720p/720p.mp4?loc=external&signature=f93ace7c9317d40feabcc8b3327ece71cce6559b1aea3512904cc7f6697c571e">
        </video>
      </div>


      <div class="md:mt-16 md:pt-16 w-full md:flex py-4">
        <img class="py-2 px-2 w-full md:w-2/6" src="../img/projects/crown-entry_mural_white_a.webp" alt="Crown Mural"/>
        <img class="py-2 px-2 w-full md:w-2/6" src="../img/projects/light and artwork_without sign.webp" alt="Artwork"/>
        <img class="py-2 px-2 w-full md:w-2/6" src="../img/projects/winecellar_lighting_tom_01-a.png" alt="Wine Cellar"/>
      </div>

      <img data-scroll data-scroll-offset="100" data-scroll-speed="2" class="w-full md:pt-20 md:pb-20"
        src="../img/projects/lighting-good-shot.png" alt="Projector Art"/>

        <div class="flex flex-wrap md:p-16 md:m-16 px-5 py-8">
          <div class="lg:w-3/6 px-5 pb-4 md:pb-0">
            <p class="text-xl md:text-3xl ">
            In the summer of 2019, USI hosted a culture shop takeover on the High Street of Belfast. 
            The shop welcomed over 1500 visitors through its doors.We worked with individuals and groups from across the 
            city to understand what lightning in the city could be. People had all sorts of ideas from sustainable, playful, 
            interactive and safe public lighting. This helped us develop a set of design principles unique to Belfast - 
            developed by the people.
            </p>
          </div>
          <div class="lg:w-3/6 px-5">
            <img src="../img/projects/imagining2.webp"/>            
          </div>
        </div>

        <div class="flex flex-wrap  md:p-16 md:m-16 px-5 py-8">
          <div class="lg:w-3/6 px-5 pb-4 md:pb-0">
            <img src="../img/projects/luminous.webp"/>            
          </div>
          <div class="lg:w-3/6 px-5">
            <p data-scroll data-scroll-speed="2" class="text-xl md:text-3xl ">
            Following the co-design process we worked with Belfast City Council to develop a range of design principles 
            and typologies for the city which fed into the Lighting Strategy ‘A Luminous City’. 
            These take the insights from the culture shop and make them deliverable for lighting interventions across the city. 
            For example recognising different lighting depending on the site or the event.<br/><br/>
            The design principles; which range from safety to connection, interactivity to distinctly Belfast ensure that all lighting 
            designed for the city going forward has a consistent design, with room for creativity.
            </p>
          </div>
        </div>

      <div data-scroll data-scroll-offset="100" data-scroll-speed="2"
        class="md:mt-16 md:pt-16 max-w-full md:flex py-4 md:pl-20 md:pr-20 w-screen">
        <img class="py-2 px-2 w-full md:w-4/6 h-3/6" src="../img/projects/reflection.png" alt="Reflection"/>
        <img class="py-2 px-2 w-full md:w-2/6" src="../img/projects/rainy-alley.png" alt="Alley"/>
      </div>

        <div class="flex flex-wrap md:p-16 md:m-16 px-5 py-5">
          <div class="lg:w-3/6 px-5">
            <img src="../img/projects/trailing-green-light2.png"/>            
          </div>
          <div class="lg:w-3/6 px-5 py-5">
            <p data-scroll data-scroll-speed="2" class="text-xl md:text-3xl ">
            The final result has created a playful new experience within the city. 
            Not only making the spaces safer but also creating a new destination.<br/><br/>
            In Castle Arcade and Winecellar entry we collaborated with internationally 
            renowned lighting artists Squidsoup to create a dynamic lighting piece with a unique soundscape. 
            The soundscapes are inspired by the built context and site histories. The lights respond to movement within the entries.<br/><br/>
            Importantly, sustainability was at the heart of the process, with the orbs at Winecellar being made locally through 3D printing - 
            being one of the first zero-waste public installations. 
            </p>
          </div>
        </div>   

        <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-blue-900">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../cityimagining" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
